// store.js

import { createStore } from 'redux';

// Define los tipos de acción
const SET_SIDEBAR_SHOW = 'SET_SIDEBAR_SHOW';
const TOGGLE_SIDEBAR_UNFOLDABLE = 'TOGGLE_SIDEBAR_UNFOLDABLE';

// Crea los creadores de acción
export const setSidebarShow = (show) => ({
  type: SET_SIDEBAR_SHOW,
  sidebarShow: show,
});

export const toggleSidebarUnfoldable = () => ({
  type: TOGGLE_SIDEBAR_UNFOLDABLE,
});

// Define el estado inicial
const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
};

// Modifica el reducer para manejar las acciones
const changeState = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIDEBAR_SHOW:
      return { ...state, sidebarShow: action.sidebarShow };
    case TOGGLE_SIDEBAR_UNFOLDABLE:
      return { ...state, sidebarUnfoldable: !state.sidebarUnfoldable };
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
